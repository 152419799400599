import { SIDEBAR } from '../action_const'

let initState = {
  isShowSidebar: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case SIDEBAR.TOGGLE_SIDEBAR:
      return Object.assign({}, state, {
        isShowSidebar: !state.isShowSidebar
      })
    default:
      return state
  }
}