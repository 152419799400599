import Axios from 'axios'

export const register = (accessToken, data) => {
  return Axios({
    method: 'post',
    url: process.env.REACT_APP_ACCOUNT_API + '/apis/az/wallet',
    headers: { Authorization: accessToken },
    data: data
  })
}
export const updatePassword = (accessToken, password) => {
  return Axios({
    method: 'post',
    url: process.env.REACT_APP_ACCOUNT_API + '/apis/az/updatePassword',
    headers: { Authorization: accessToken },
    data: { password }
  })
}

export const sendEmailVerification = (accessToken) => {
  return Axios({
    method: 'get',
    url: process.env.REACT_APP_ACCOUNT_API + '/apis/az/sendEmailVerification',
    headers: { Authorization: accessToken },
  })
}


export const getVerifyEmail = (code) => {
  return Axios({
    method: 'get',
    url: process.env.REACT_APP_ACCOUNT_API + '/apis/verifyEmail?code=' + code,
  })
}


export const sendResetPassword = (email) => {
  return Axios({
    method: 'post',
    url: process.env.REACT_APP_ACCOUNT_API + '/apis/sendResetPassword',
    data: {
      email: email
    }
  })
}

export const resetPassword = (new_password, code) => {
  return Axios({
    method: 'post',
    url: process.env.REACT_APP_ACCOUNT_API + '/apis/resetPassword',
    data: {
      new_password: new_password,
      code: code
    }
  })
}

export const getResetPassword = (code) => {
  return Axios({
    method: 'get',
    url: process.env.REACT_APP_ACCOUNT_API + '/apis/resetPassword?code=' + code
  })
}
