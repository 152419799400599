import styled, { css } from 'styled-components'

export const PointspotLogoBox = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  border-bottom: 1px solid #00000015;
`

export const PointspotLogo = styled.img`
  width: 70%;
  height: auto;
`

export const MenuIcon = styled.div`
  width: 30px;
  height: auto;
  font-size: 15px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-right: 15px;
`

export const MenuItem = styled.div`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;

  margin: 5px 10px;
  padding: 10px 15px;
  border-radius: 18px;

  ${props => props.active
    && css`background-color: #00000015`
  }
`

export const SidebarBox = styled.div`
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const IconMenu = styled.i`
  ${props => props.active
    ? css`color: ${props => props.theme.primary}`
    : css`color: ${props => props.theme.gray}`
  }
`