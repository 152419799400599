import { injectGlobal } from 'styled-components'
import { theme } from './theme'

injectGlobal`
  *{
    outline: none !important;
   }

  input.form-control, textarea.form-control, select.form-control{
    font-size: 14px !important;
    color:${theme.primary} !important;
  }

  input.form-control::placeholder, textarea.form-control::placeholder, select.form-control::placeholder{
    font-size: 14px !important;
    color:${theme.input_placeholder} !important;
  }

  input.form-control-xs, textarea.form-control-xs, select.form-control-xs{
    font-size: 12px !important;
    @media (max-width: 990px) {
      font-size: 10px !important;
    }
    color:${theme.primary} !important;
  }

  input.form-control-xs::placeholder, textarea.form-control-xs::placeholder, select.form-control-xs::placeholder{
    font-size: 12px !important;
    @media (max-width: 990px) {
      font-size: 10px !important;
    }
    color:${theme.input_placeholder} !important;
  }
  
  input.patipong{
    background-color: red;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
      border-radius: 15px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #C3C3C4; 
    border-radius: 15px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #9C9C9C;
  }
`;