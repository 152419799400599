import axios from 'axios'
import Cookies from 'js-cookie'
import { PAYMENT } from '../action_const'

export const getCustomerPayment = () => {
  return dispatch => {
    dispatch({ type: PAYMENT.PAYMENT_LOADING })
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment`,
      headers: { authorization: Cookies.get('__fbToken') }
    }).then(data => {
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_SUCCESS, data: data.data })
    }).catch(() => {
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_FAIL })
    })
  }
}

export const createCustomerPayment = () => {
  return dispatch => {
    dispatch({ type: PAYMENT.PAYMENT_LOADING })
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment`,
      headers: { authorization: Cookies.get('__fbToken') }
    }).then((data) => {
      dispatch({ type: PAYMENT.CREATE_PAYMENT_CUSTOMER_SUCCESS, data: data.data })
    }).catch(() => {
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_FAIL })
    })
  }
}

const error = (error) => {
  let errorType = [{
    code: "processing_error",
    message: {
      en: "An error occurred while processing your card. Try again in a little bit.",
      th: "เกิดข้อผิดพลาดขณะประมวลผลบัตรของคุณ ลองใหม่อีกครั้ง"
    }
  }, {
    code: "invalid_cvc",
    message: {
      en: "Your card's security code is invalid.",
      th: "รหัสความปลอดภัยของบัตรของคุณไม่ถูกต้อง"
    }
  }, {
    code: "incorrect_cvc",
    message: {
      en: "Your card's security code is incorrect.",
      th: "รหัสความปลอดภัยของบัตรของคุณไม่ถูกต้อง"
    }
  }, {
    code: "card_declined",
    message: {
      en: "Your card is not supported and was declined.",
      th: "บัตรของคุณไม่รองรับและถูกปฏิเสธ"
    }
  }, {
    code: "expired_card",
    message: {
      en: "Your card has expired.",
      th: "บัตรของคุณหมดอายุ"
    }
  }, {
    code: "incorrect_number",
    message: {
      en: "Your card number is incorrect.",
      th: "หมายเลขบัตรของคุณไม่ถูกต้อง"
    }
  }]

  return (errorType.filter(x => x.code === error).length > 0)
    ? errorType.filter(x => x.code === error)[0]
    : errorType[0]
}

export const getCustomerCreditCard = (callback) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/card`,
      headers: { authorization: Cookies.get('__fbToken') },
    }).then(data => {
      if (typeof callback === 'function') callback(null, data.data)
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_CARD_SUCCESS, data: data.data })

    }).catch((err) => {
      if (typeof callback === 'function') callback(err)
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_CARD_FAIL })
    })
  }
}

export const createCreditCard = (card, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/card`,
      headers: { authorization: Cookies.get('__fbToken') },
      data: { card: card }
    }).then(data => {
      if (data.data.result) {
        if (typeof callback === 'function') callback(null, data.data.result)
        dispatch({ type: PAYMENT.PAYMENT_LOADING })
        setTimeout(() => { dispatch({ type: PAYMENT.PAYMENT_LOADING_SUCCESS }) }, 500)

      } else {
        let { internal_code } = data.data.error
        if (typeof callback === 'function') callback(error(internal_code.code))

      }
    }).catch(() => {
      if (typeof callback === 'function') callback(error())
    })
  }
}

export const deleteCreditCard = (card_token, callback) => {
  return dispatch => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/card`,
      headers: { authorization: Cookies.get('__fbToken') },
      data: { card_token: card_token }
    }).then(data => {
      if (data.data.result) {
        if (typeof callback === 'function') callback(null, data.data.result)
        dispatch({ type: PAYMENT.PAYMENT_LOADING })
        setTimeout(() => { dispatch({ type: PAYMENT.PAYMENT_LOADING_SUCCESS }) }, 200)

      } else {
        if (typeof callback === 'function') callback(error())

      }
    }).catch(() => {
      dispatch({ type: PAYMENT.PAYMENT_LOADING })
      setTimeout(() => {
        dispatch({ type: PAYMENT.PAYMENT_LOADING_SUCCESS })
      }, 200)
    })
  }
}

export const getCustomerProduct = (callback, callback_fail) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/product`,
      headers: { authorization: Cookies.get('__fbToken') },
    }).then(data => {
      if (typeof callback === 'function') callback(data.data.result)
      return dispatch({ type: PAYMENT.GET_PAYMENT_PRODUCT_LIST, data: data.data })
    }).catch(err => {
      if (typeof callback_fail === 'function') callback_fail()
      return dispatch({ type: PAYMENT.GET_PAYMENT_PRODUCT_LIST, data: {} })
    })
  }
}