import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loading from '../Loading'

import { getBusinessList } from '../../redux/actions/action_business'
class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }
  componentDidMount() {
    this.props.getBusinessList(
      (err, data) => { this.setState({ isLoading: false }) }
    )
  }
  render() {
    let { isLoading } = this.state
    return isLoading
      ? <Loading />
      : this.props.children
  }
}


const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getBusinessList
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)