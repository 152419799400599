import React from 'react';
import ReactDOM from 'react-dom';

import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import Reducer from './redux/reducer'

import AppRoute from './AppRoute';
import { unregister } from './registerServiceWorker';

import './index.css'
import './styles/globalStyle'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.css'

const store = createStore(
  Reducer,
  applyMiddleware(ReduxThunk)
)

ReactDOM.render(
  <Provider store={store}>
    <AppRoute />
  </Provider>,
  document.getElementById('root')
);

unregister();