import axios from 'axios'
import Cookies from 'js-cookie'
import { BUSINESS } from '../action_const'

export const getBusinessList = (call_back) => {
  return dispatch => {
    dispatch({ type: BUSINESS.BUSINESS_LOADING })
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_API}/business`,
      headers: { authorization: Cookies.get('__fbToken') }
    }).then(data => {
      if (typeof call_back === 'function') call_back(null, data.data)
      dispatch({ type: BUSINESS.GET_BUSINESS_LIST, data: data.data })
    }).catch(err => {
      if (typeof call_back === 'function') call_back(null, {})
      dispatch({ type: BUSINESS.GET_BUSINESS_LIST, data: {} })
    })
  }
}