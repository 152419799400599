import Cookies from 'js-cookie'
import firebase from './firebase'

let cookiesOptions = { domain: process.env.REACT_APP_COOKIEDOMAIN }

export const setRedirect = (__dst, __tkn) => {
  Cookies.set('__dst', __dst)
  Cookies.set('__tkn', __tkn, cookiesOptions)
  Cookies.set('__ref', window.document.referrer || process.env.REACT_APP_LOYALTY)
}

export const setLoyalty = () => {
  Cookies.set('__dst', 'Loyalty')
  Cookies.set('__tkn', '1', cookiesOptions)
  Cookies.set('__ref', process.env.REACT_APP_LOYALTY);
}

export const chkRedirect = (isLogin) => {
  return new Promise((resolve, reject) => {
    if (!isLogin) return resolve()
    getCookie()
      .then(result => {
        if (result[0] && result[1]) {
          firebase.firestore().collection('user_token').doc(result[1])
            .set({
              uid: firebase.auth().currentUser.uid,
              iss: new Date(),
              service: result[0]
            }).then(data => {
              window.location.href = result[2]
            }).catch(resolve)
        } else {
          if (process.env.REACT_APP_FORCE_REDIRECT) window.location.href = process.env.REACT_APP_LOYALTY
          resolve()
        }
      })
  })
}

const getCookie = () => {
  let __dst = Cookies.get('__dst')
  let __tkn = Cookies.get('__tkn')
  let __ref = Cookies.get('__ref')
  Cookies.remove('__dst')
  Cookies.remove('__tkn')
  Cookies.remove('__ref')
  return Promise.resolve([__dst, __tkn, __ref])
}
