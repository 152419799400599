import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Sidebar from 'react-sidebar'
import { Link } from 'react-router-dom'

import {
  SidebarBox,
  PointspotLogoBox, PointspotLogo, IconMenu,
  MenuIcon, MenuItem
} from './sidebar.style'

import { Title2 } from '../Font'

import Logo from '../../assets/image/logo.svg'
import { toggleSidebar } from '../../redux/actions/action_sidebar'
import { getCustomerPayment } from '../../redux/actions/action_payment'


class SideBarList extends React.Component {
  componentDidMount() {
    this.props.getCustomerPayment()
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { store_payment: { payment } } = this.props

    let menu = [
      {
        show: false,
        title: 'Dashboard',
        icon: 'fas fa-chart-pie',
        href: '/dashboard',
      },
      {
        show: true,
        title: dictionary.profile,
        icon: 'fas fa-user-friends',
        href: '/profile',
      },
      {
        show: false,
        title: dictionary.package,
        icon: 'fas fa-box-open',
        href: '/package',
      },
      {
        show: true,
        title: dictionary.payment_method,
        icon: 'fas fa-credit-card',
        href: '/payment',
      },
      {
        show: Boolean(payment && payment.user && payment.user.customer_token_id),
        title: `${dictionary.invoice} / ${dictionary.receipt}`,
        icon: 'fas fa-file-invoice',
        href: '/invoices',
      },
    ]
    return (
      <SidebarBox>
        <div>
          <PointspotLogoBox>
            <PointspotLogo alt='' src={Logo} />
          </PointspotLogoBox>
          {
            menu.map((value, key) => {
              let href = this.props.location.pathname
              let active = (href === value.href)
              if (value.href === '/profile') {
                active = ['/profile', '/editprofile', '/resetpassword'].indexOf(href) > -1
              }

              return value.show && <div key={key}>
                <Link to={value.href} style={{ textDecoration: 'none' }} onClick={this.props.toggleSidebar}>
                  <MenuItem active={active}>
                    <MenuIcon>
                      <IconMenu className={value.icon} active={active} />
                    </MenuIcon>
                    <Title2 bold gray={!active}>{value.title}</Title2>
                  </MenuItem>
                </Link>
              </div>
            })
          }
        </div>
        <div>

        </div>
      </SidebarBox>
    )
  }
}

class SidebarComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      docked: true
    }
  }

  componentDidMount() {
    this.setState({ docked: window.innerWidth >= 992 })
    window.onresize = () => {
      this.setState({ docked: window.innerWidth >= 992 })
    }
  }

  render() {
    let { store_sidebar, toggleSidebar } = this.props
    return (
      <Sidebar
        touch={false}
        shadow={!false}
        onSetOpen={toggleSidebar}
        docked={this.state.docked}
        open={store_sidebar.isShowSidebar}
        sidebar={<SideBarList  {...this.props} />}
        styles={{
          sidebar: {
            zIndex: 1030,
            width: '90%',
            minWidth: '250px',
            maxWidth: '250px',
            background: '#FFF'
          },
          content: {
            overflowY: 'auto',
          },
          overlay: {
            zIndex: 1029
          }
        }}>
        {this.props.children}
      </Sidebar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language,
    store_sidebar: state.sidebar,
    store_payment: state.payment,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    toggleSidebar,
    getCustomerPayment
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent)