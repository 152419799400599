import { PAYMENT } from '../action_const'

let initState = {
  isLoading: false,
  payment: {},
  payment_card: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case PAYMENT.PAYMENT_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      })
    case PAYMENT.PAYMENT_LOADING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      })
    case PAYMENT.GET_PAYMENT_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        payment: action.data.data
      })
    case PAYMENT.CREATE_PAYMENT_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        payment: {
          user: action.data.data
        }
      })
    case PAYMENT.GET_PAYMENT_CUSTOMER_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        payment: Object.assign({}, state.payment, {})
      })
    case PAYMENT.GET_PAYMENT_CUSTOMER_CARD_SUCCESS:
      return Object.assign({}, state, {
        payment_card: action.data.data
      })
    case PAYMENT.GET_PAYMENT_CUSTOMER_CARD_FAIL:
      return Object.assign({}, state, {
        payment_card: {}
      })
    case PAYMENT.GET_PAYMENT_PRODUCT_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        payment: Object.assign({}, state.payment, {
          product_subscription: action.data.data
        })
      })
    default:
      return state
  }
}