import PackageConfig from './../../package.json';

const node_env = process.env.REACT_APP_ENV || 'development'
const isDebug = node_env !== 'production'

const TRACE = (...args) => {
    var _console = console;
    (_console = console).log.apply(_console, args);
}
const DEBUG = (...args) => {
    if (!isDebug) return;
    var _console = console;
    (_console = console).log.apply(_console, args);
}
const ERROR = (...args) => {
    if (!isDebug) return;
    var _console = console;
    (_console = console).error.apply(_console, args);
}

export default {
    package: PackageConfig,
    diagnostic: { TRACE, DEBUG, ERROR },
    landingpage: 'https://goo.gl/TXq2yF'
}