export const theme = {
  primary: '#546174',
  secondary: '#7d889a',
  input_placeholder: '#B1BCCF',
  gray: '#9da6ad',
  green: '#00C851',
  red: '#FF4444',
  orange: '#FFBB33',
  yellow: '#ffc107',
  blue: '#33B5E5',
  white: '#fafafa',
  white_2: '#F0F0F0',
  white_1: '#FFFFFF',
  white_border: '#e6e6e6',
  white_hilight: '#f0f0f0',
  
  font_xxl: '19px',
  font_xl: '17px',
  font_l: '15px',
  font_m: '13px',
  font_s: '11px',
  font_xs: '9px',
  font_xxs: '7px',
  font_bold: '700'
}