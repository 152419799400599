import React from 'react'
import { Button } from 'reactstrap'
import { Title2 } from '../../components/Font'
export default class extends React.Component {
  render() {
    return this.props.isLoading
      ? <Button
        disabled
        color="success"
        className={this.props.className}>
        <span>
          <Title2 white>
            <i className="fas fa-spinner fa-pulse"></i>
          </Title2>
        </span>
      </Button>
      : <Button
        type='submit'
        color="success"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        className={this.props.className}>
        {this.props.children}
      </Button>
  }
}