import { PERMISSION } from '../action_const'

let initState = {
  isPageLoading: true,
  isLoading: false,
  isVerify: false,
  isLogin: false,
  isRedirect: true,
  isError: false,
  isShow: false,
  profile: {},
  providerData: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case PERMISSION.USER_NOT_AUTH:
      return Object.assign({}, state, {
        isPageLoading: false
      })
    case PERMISSION.GET_SOCIAL_CREDENTIAL:
      return Object.assign({}, state, {
        providerData: action.data
      })
    case PERMISSION.REMOVE_SOCIAL_CREDENTAIL:
      return Object.assign({}, state, {
        providerData: null
      })
    case PERMISSION.REMOVE_LOGIN_ERROR:
      return Object.assign({}, state, {
        isError: false,
      })
    case PERMISSION.USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isLogin: true,
        isError: false,
        isShow: false,
        isPageLoading: false,
        isVerify: action.data.emailVerified,
        profile: action.data,
      })
    case PERMISSION.CREATE_USER_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        isLogin: false,
        isShow: false,
        message: action.error.message,
        error: action.error
      })
    case PERMISSION.USER_LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
      })
    case PERMISSION.USER_LOGIN_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isLogin: false,
        isError: true,
        isShow: false,
        isPageLoading: false,
        message: action.error.message,
        error: action.error
      })
    case PERMISSION.CREATE_USER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
      })
    case PERMISSION.SET_REDIRECT_ROUTE:
      return Object.assign({}, state, {
        isRedirect: action.data
      })
    case PERMISSION.USER_LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isLogin: false,
        isShow: false,
        isPageLoading: false,
        isError: false,
        profile: {},
        providerData: null
      })
    case PERMISSION.EMAIL_ALREADY_VERIFIED:
      return Object.assign({}, state, {
        isVerify: true
      })
    case PERMISSION.UPDATE_USER_LOADING:
      return Object.assign({}, state, {
        isLoading: true
      })
    case PERMISSION.UPDATE_AVATAR_USER:
      let profilePhotoURL = Object.assign(state.profile, {
        photoURL: action.data
      });
      return Object.assign({}, state, {
        isLoading: false,
        profile: profilePhotoURL
      })
    case PERMISSION.UPDATE_PROFILE_USER:
      return Object.assign({}, state, {
        isLoading: false,
        profile: Object.assign({}, state.profile, {
          name: action.data.name,
          last_name: action.data.last_name,
          phone: action.data.phone,
        })
      })
    case PERMISSION.SENT_EMAIL_RESETPASSWORD_LOADING:
      return Object.assign({}, state, {
        isLoading: true
      })
    case PERMISSION.SENT_EMAIL_RESETPASSWORD:
      return Object.assign({}, state, {
        isLoading: false,
        isShow: true
      })
    case PERMISSION.PERMISSION_IS_LOADING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    default:
      return state
  }
}

