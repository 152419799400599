export default {
  hello: 'HELLO!',
  continue_with_google: 'Continue with Google',
  continue_with_facebook: 'Continue with Facebook',
  accept_terms: 'By signing in to system you agree to all the',
  terms_conditions: 'Terms & Conditions',
  privacy_policy: 'Privacy Policy',
  signup: 'Create new account',
  signin: 'Sign in',
  signout: 'Sign out',
  email: 'Email',
  password: 'Password',
  reset_password: 'Reset password',
  forget_password: 'Forget password',
  singin_with_pointspot_account: 'Sign in with your PointSpot account',
  email_or_password_is_incorrect: 'Email or Password is incorrect.',
  congratulations: 'Congratulations',
  email_verified: 'Your email has been verified.',
  firstname: 'First name',
  lastname: 'Last name',
  contact_phone: 'Contact Phone',
  set_password: 'New password',
  confirm_password: 'Confirm password again',
  submit: 'Submit',
  confirm: 'Confirm',
  back: 'Back',
  or: 'OR',
  email_incorrect: 'Email is incorrect',
  email_already_in_use: 'Email is already in use',
  weak_password: 'Password must be at least 6 characters',
  password_not_match: 'Password not match',
  logout: 'Logout',
  check_email: 'Please check email',
  crop: 'Crop',
  cancel: 'Cancel',
  save: 'Save',
  create: 'Create',
  next: 'Next',
  and: 'and',
  business: 'Business',
  update: 'Update',
  create_business: 'Create business',
  
  // Proflie
  profile: 'Profile',
  wallet_id: 'Wallet ID',
  check_email_for_reset_password: 'Link for reset your password is send to',
  pointspot_account: 'PointSpot Account',
  new_password: 'New password',
  password_changed: 'Password changed',
  signin_with_new: 'You can now sign in with your new password',
  resend_email: 'Resend email',
  dont_receive_email: 'Didn\'t receive email ?',
  please_verify_email: 'Please verify your email address',
  please_check_email: `Please check your email`,
  please_check_email2: `And confirm by email that PointSpot sent to`,
  edit_profile: 'Edit Profile',
  edit_password: 'Edit Password',
  select_photo: 'Select Photo',
  name_surname: 'Name & Surname',
  limit_characters: 'Limit 30 Characters',
  confirm_email: 'Are you want to reset password from this email ?',
  require_email_reset: 'Reset password for this email',
  edit_profile_success: 'Edit profile success',
  no_results_found: 'No results found',

  // Package
  package: 'Package',
  default_credit_card: 'Default',
  you_want_delete_credit_card: 'Do you want to delete the credit card ?',
  add_credit_card: 'Add Credit Card',
  credit_card_number: 'Credit Card Number',
  when_doing: 'When doing',
  you_understand_and_accept: 'You understand and accept',
  cardholder_name: 'Cardholder Name',
  expiration_month: 'Expiration month',
  expiration_year: 'Expiration year',
  delete_credit_card: 'Delete credit card',

  // Paymant Method
  payment_method: 'Payment Method',
  terms_payment: 'Payment terms',
  terms_payment_list: [{
    title: '1. รูปแบบการชำระเงิน',
    description: 'การชำระเงินค่าบริการของ PointSpot จะชำระผ่านบัตรเครดิตในระบบออนไลน์ โดยระบบจะสรุปยอดค่าใช้จ่ายในแต่ละเดือนในวันสุดท้ายของเดือน และทำการตัดชำระเงินจากบัตรที่ได้ตั้งค่าไว้ในระบบ ซึ่งกรณี ระบบไม่สามารถตัดชำระเงินผ่านบัตรได้ ด้วยเหตุผลต่างๆ เช่นบัตรหมดอายุ หรือ วงเงินในบัตรไม่พอ อาจส่งผลทำให้ไม่สามารถใช้บริการได้ จนกว่าจะมีการชำระเงินคงค้างนั้น'
  }, {
    title: '2. บริษัทผู้ให้บริการระบบ PointSpot',
    description: 'บริษัทที่เป็นผู้ให้บริการ PointSpot คือ บริษัทเรดดี้แพลนเน็ตเอเชียแปซิฟิค (ReadyPlanet Asia Pacific Limited) ซึ่งจดทะเบียนและตั้งอยู่ที่ฮ่องกง (Hong Kong) ราคาค่าบริการที่คิด เป็นราคาที่ไม่รวมภาษีมูลค่าเพิ่ม'
  }, {
    title: '3. ระบบรักษาความปลอดภัยของการชำระเงิน',
    description: 'PointSpot ใช้ระบบชำระเงินออนไลน์จากบริษัท Stripe ซึ่งเป็นผู้ให้บริการด้านรับชำระเงินออนไลน์ระดับโลก โดยข้อมูลหมายเลขบัตรเครดิตไม่ได้เก็บรักษาไว้ที่ในระบบ PointSpot แต่เก็บรักษาไว้ที่บริษัทผู้ให้บริการชำระเงิน เพื่อเหตุผลด้านความปลอดภัย '
  }],

  // Invoice Receipt
  invoice: 'Invoice',
  receipt: 'Receipt',
  status: 'Status',
  download: 'Download',
  balance: 'Balance',
  processing: 'Processing',
  paid: 'Paid',
  failed: 'Failed',
  pay: 'Pay',

}