import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setRedirect } from '../../libs/AppRedirect'

class LoginWithRedirect extends React.Component {

  componentDidMount() {
    let { match: { params } } = this.props
    setRedirect(params.service, params.token)
  }

  render() {
    return null
  }
}


const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithRedirect)