import styled, { css } from 'styled-components'

const fontStyle = styled.div`
  color: ${props => props.theme.primary};
  ${props => props.primary && css`color: ${props.theme.primary};`}
  ${props => props.secondary && css`color: ${props.theme.secondary};`}
  ${props => props.white && css`color: ${props.theme.white};`}
  ${props => props.bold && css`font-weight: ${props.theme.font_bold};`}
  ${props => props.danger && css`color: ${props.theme.red};`}
  ${props => props.success && css`color: ${props.theme.green};`}
  ${props => props.warning && css`color: ${props.theme.orange};`}
  ${props => props.yellow && css`color: ${props.theme.yellow};`}
  ${props => props.gray && css`color: ${props.theme.gray};`}
  ${props => props.blue && css`color: ${props.theme.blue};`}
  
  ${props => props.flex && css`
    display: flex;
    align-items: center;
  `}

  ${props => props.link && css`
    cursor: pointer;
    color: ${ props.theme.link};
    &:hover{
      opacity:0.9;
    }
  `}

  ${props => props.center && css`
    text-align: center;
  `}
`

export const TitlePointspot = styled(fontStyle)`
    font-size: 26px;
  @media (max-width: 990px) {
    font-size: 24px;
  }
`

export const TitleMain1 = styled(fontStyle)`
   font-size: ${props => props.theme.font_xxl};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xl};
  }
`

export const TitleMain2 = styled(fontStyle)`
   font-size: ${props => props.theme.font_xl};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_l};
  }
`

export const Title1 = styled(fontStyle)`
   font-size: ${props => props.theme.font_l};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_m};
  }
`

export const Title2 = styled(fontStyle)`
  font-size: ${props => props.theme.font_m};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
  }
`

export const Title3 = styled(fontStyle)`
  font-size: ${props => props.theme.font_s};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xs};
  }
`

export const Title4 = styled(fontStyle)`
  font-size: ${props => props.theme.font_xs};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xxs};
  }
`