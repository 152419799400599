import { BUSINESS } from '../action_const'

let initState = {
  isLoading: false,
  business_info: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case BUSINESS.BUSINESS_LOADING:
      return Object.assign({}, state, {
        isLoading: true
      })
    case BUSINESS.GET_BUSINESS_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        business_info: action.data.data
      })
    default:
      return state
  }
}