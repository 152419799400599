export default {
  hello: 'สวัสดีครับ!',
  continue_with_google: 'เข้าสู่ระบบด้วย Google',
  continue_with_facebook: 'เข้าสู่ระบบด้วย Facebook',
  accept_terms: 'การล็อคอินเข้าสู่ระบบนี้ ท่านยอมรับ',
  terms_conditions: 'เงื่อนไขการใช้บริการ',
  privacy_policy: 'นโยบายข้อมูลส่วนบุคคล',
  signup: 'สร้างบัญชีใหม่',
  signin: 'เข้าสู่ระบบ',
  signout: 'ออกจากระบบ',
  email: 'อีเมล',
  password: 'รหัสผ่าน',
  reset_password: 'รีเซ็ตรหัสผ่าน',
  forget_password: 'ลืมรหัสผ่าน',
  singin_with_pointspot_account: 'เข้าสู่ระบบด้วยบัญชี PointSpot',
  email_or_password_is_incorrect: 'อีเมลล์หรือรหัสผ่านไม่ถูกต้อง',
  congratulations: 'ขอแสดงความยินดี',
  email_verified: 'อีเมลของคุณได้รับการยืนยันแล้ว',
  firstname: 'ชื่อจริง',
  lastname: 'นามสกุล',
  set_password: 'ตั้งค่ารหัสผ่าน',
  confirm_password: 'ยืนยันรหัสผ่านอีกครั้ง',
  contact_phone: 'เบอร์โทรศัพท์ที่สามารถติดต่อได้',
  submit: 'ยืนยัน',
  confirm: 'ยืนยัน',
  back: 'ย้อนกลับ',
  or: 'หรือ',
  email_incorrect: 'อีเมลไม่ถูกต้อง',
  email_already_in_use: 'อีเมลมีผู้ใช้แล้ว',
  weak_password: 'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
  password_not_match: 'รหัสผ่านไม่เหมือนกัน',
  check_email: 'กรุณาตรวจสอบอีเมล',
  logout: 'ออกจากระบบ',
  crop: 'ตัด',
  cancel: 'ยกเลิก',
  save: 'บันทึก',
  create: 'สร้าง',
  next: 'ต่อไป',
  and: 'และ',
  business: 'ธุรกิจ',
  update: 'อัพเดท',
  create_business: 'สร้างธุรกิจ',

  // Proflie
  profile: 'โปรไฟล์',
  wallet_id: 'Wallet ID',
  check_email_for_reset_password: 'ลิ้งสำหรับการรีเซ็ตรหัสผ่านถูกส่งเข้าไปยัง',
  pointspot_account: 'บัญชี PointSpot',
  new_password: ' รหัสผ่านใหม่',
  password_changed: 'รหัสผ่านถูกเปลี่ยนแล้ว',
  signin_with_new: 'ผู้ใช้สามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่',
  resend_email: 'ส่งอีเมลอีกครั้ง',
  dont_receive_email: 'ไม่ได้รับอีเมลใช่ไหม ?',
  please_verify_email: 'กรุณายืนยันอีเมล',
  please_check_email: 'โปรดตรวจสอบอีเมลของท่าน',
  please_check_email2: 'และกดยืนยันจากอีเมลที่ทาง PointSpot ส่งให้ที่',
  edit_profile: 'แก้ไขข้อมูลส่วนตัว',
  edit_password: 'แก้ไขรหัสผ่าน',
  select_photo: 'เลือกรูปภาพ',
  name_surname: 'ชื่อ - นามสกุล',
  limit_characters: 'ไม่เกิน 30 ตัวอักษร',
  confirm_email: 'คุณต้องการรีเซ็ตรหัสผ่านสำหรับอีเมลนี้หรือไม่ ?',
  require_email_reset: 'รีเซ็ตรหัสผ่านสำหรับอีเมลนี้',
  edit_profile_success: 'แก้ไขข้อมูลส่วนตัวสำเร็จ',
  no_results_found: 'ไม่พบรายการ',

  // Package
  package: 'แพ็คเกจ',
  default_credit_card: 'ค่าเริ่มต้น',
  you_want_delete_credit_card: 'คุณต้องการลบบัตรเครดิตหรือไม่ ?',
  add_credit_card: 'เพิ่มบัตรเครดิต',
  credit_card_number: 'หมายเลขบัตรเครดิต',
  when_doing: 'เมื่อทำการ',
  you_understand_and_accept: 'ท่านเข้าใจและยอมรับ',
  cardholder_name: 'ชื่อผู้ถือบัตร',
  expiration_month: 'เดือนที่หมดอายุ',
  expiration_year: 'ปีที่หมดอายุ',
  delete_credit_card: 'ลบบัตรเครดิต',

  // Paymant Method
  payment_method: 'ช่องทางการชำระเงิน',
  terms_payment: 'เงื่อนไขการชำระเงิน',
  terms_payment_list: [{
    title: '1. รูปแบบการชำระเงิน',
    description: 'การชำระเงินค่าบริการของ PointSpot จะชำระผ่านบัตรเครดิตในระบบออนไลน์ โดยระบบจะสรุปยอดค่าใช้จ่ายในแต่ละเดือนในวันสุดท้ายของเดือน และทำการตัดชำระเงินจากบัตรที่ได้ตั้งค่าไว้ในระบบ ซึ่งกรณี ระบบไม่สามารถตัดชำระเงินผ่านบัตรได้ ด้วยเหตุผลต่างๆ เช่นบัตรหมดอายุ หรือ วงเงินในบัตรไม่พอ อาจส่งผลทำให้ไม่สามารถใช้บริการได้ จนกว่าจะมีการชำระเงินคงค้างนั้น'
  }, {
    title: '2. บริษัทผู้ให้บริการระบบ PointSpot',
    description: 'บริษัทที่เป็นผู้ให้บริการ PointSpot คือ บริษัทเรดดี้แพลนเน็ตเอเชียแปซิฟิค (ReadyPlanet Asia Pacific Limited) ซึ่งจดทะเบียนและตั้งอยู่ที่ฮ่องกง (Hong Kong) ราคาค่าบริการที่คิด เป็นราคาที่ไม่รวมภาษีมูลค่าเพิ่ม'
  }, {
    title: '3. ระบบรักษาความปลอดภัยของการชำระเงิน',
    description: 'PointSpot ใช้ระบบชำระเงินออนไลน์จากบริษัท Stripe ซึ่งเป็นผู้ให้บริการด้านรับชำระเงินออนไลน์ระดับโลก โดยข้อมูลหมายเลขบัตรเครดิตไม่ได้เก็บรักษาไว้ที่ในระบบ PointSpot แต่เก็บรักษาไว้ที่บริษัทผู้ให้บริการชำระเงิน เพื่อเหตุผลด้านความปลอดภัย '
  }],

  // Invoice Receipt
  invoice: 'ใบแจ้งหนี้',
  receipt: 'ใบเสร็จรับเงิน',
  status: 'สถานะ',
  download: 'ดาวน์โหลด',
  balance: 'ยอดเงิน',
  processing: 'กำลังประมวลผล',
  paid: 'จ่ายสำเร็จ',
  failed: 'ล้มเหลว',
  pay: 'ชำระเงิน',

}