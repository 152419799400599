import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Container, Row, Col,
  Form, FormGroup, Input, Button,
  Card, CardBody
} from 'reactstrap'

import googleLogo from '../../assets/image/logo_google.png'
import facebookLogo from '../../assets/image/logo_facebook_white.png'
import { loginWithGoogle, loginWithFacebook } from '../../libs/socialAuth'

import { verifyEmail } from '../../libs/validator'
import { chkRedirect } from '../../libs/AppRedirect'

import config from './../../config'
import { TitlePointspot, Title1, Title2, Title3, Title4 } from '../../components/Font'
import ButtonWithLoading from '../../components/ButtonWithLoading'

import { loginWithEmailAndPassword } from '../../redux/actions/action_permission'
import { shouldRedirect, removeLoginError } from '../../redux/actions/action_permission'
import './login.css'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenProvider: {
        email: true,
        facebook: false,
        google: false
      },
      email: '',
      password: ''
    }
    this.handdleChange = this.handdleChange.bind(this)
    this.loginWithEmailAndPassword = this.loginWithEmailAndPassword.bind(this)
  }

  componentDidMount() {
    let { store_permission, history } = this.props
    if (store_permission.providerData) history.push('/signup')
    this.props.shouldRedirect(true)
    chkRedirect(store_permission.isLogin)
      .then(data => this.props.shouldRedirect(false))

  }

  handdleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  loginWithEmailAndPassword(e) {
    e.preventDefault()
    this.props.loginWithEmailAndPassword(this.state.email, this.state.password)
  }

  validateField() {
    return !this.state.email
      || !this.state.password
      || this.props.store_permission.isLoading
      || !verifyEmail(this.state.email)
  }

  render() {
    let { store_permission, store_language: { dictionary } } = this.props
    let { authenProvider } = this.state
    let { REACT_APP_TERMS_URL, REACT_APP_POLICY_URL } = process.env

    return !store_permission.isRedirect &&
      <Container fluid className="pg-login main-bg">
        <Row className="pt-4 pb-4">
          <Col xs={12} className="d-flex justify-content-center">
            <Card style={{ maxWidth: 500 }}>
              <CardBody>
                <Row>
                  <Col xs={12} className="text-center">
                    <div className='border-bottom  pb-3'>
                      <TitlePointspot>PointSpot™</TitlePointspot>
                      <Title1>{dictionary.singin_with_pointspot_account}</Title1>
                    </div>
                  </Col>
                  {
                    (authenProvider.facebook || authenProvider.google) &&
                    <Col xs={12} className='pt-3 pb-0'>
                      <Row>
                        {
                          authenProvider.facebook &&
                          <Col xs={12} className="mb-3">
                            <Button color="primary" className="facebook-login-btn d-flex align-items-center justify-content-center" onClick={() => loginWithFacebook()}>
                              <img alt="" src={facebookLogo} style={{ maxWidth: 25 }} />
                              <div style={{ width: '45%', padding: '0 15px' }}>
                                <Title2 white>{dictionary.continue_with_facebook}</Title2>
                              </div>
                            </Button>
                          </Col>
                        }
                        {
                          authenProvider.google &&
                          <Col xs={12} className="mb-3">
                            <Button color="danger" className="google-login-btn d-flex align-items-center justify-content-center" onClick={() => loginWithGoogle()}>
                              <img alt="" src={googleLogo} style={{ maxWidth: 25 }} />
                              <div style={{ width: '45%', padding: '0 15px' }}>
                                <Title2>{dictionary.continue_with_google}</Title2>
                              </div>
                            </Button>
                          </Col>
                        }
                      </Row>
                      <Col xs={12} style={{ fontSize: 12 }}>
                        <hr className="hr-text m-0" data-content={dictionary.or} />
                      </Col>
                    </Col>
                  }
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} className="pt-3 mb-1">
                        <Form onSubmit={this.loginWithEmailAndPassword} noValidate="novalidate" >
                          <FormGroup>
                            <Title2>{dictionary.email}</Title2>
                            <Input type="email" name="email" id="email" onChange={this.handdleChange} />
                          </FormGroup>
                          <FormGroup> 
                            <Title2>{dictionary.password}</Title2>
                            <Input type="password" name="password" id="password" onChange={this.handdleChange} />
                          </FormGroup>
                          <ErrorMessage
                            isError={store_permission.isError}
                            message={dictionary.email_or_password_is_incorrect} />
                          <hr />
                          <ButtonWithLoading
                            className="btn-fluid"
                            disabled={this.validateField()}
                            isLoading={store_permission.isLoading} >
                            <Title2 white id="btn-signin">{dictionary.signin}</Title2>
                          </ButtonWithLoading>
                          <br /><br />
                          <p className="text-center" style={{ fontSize: '12px' }}>
                            <span >{dictionary.accept_terms}</span><br></br>
                            <a
                              id='link-term'
                              href={REACT_APP_TERMS_URL}
                              rel="noopener noreferrer"
                              target="_blank">
                              {dictionary.terms_conditions}</a>
                            <span style={{ color: '#adadad' }}> | </span>
                            <a
                              id='link-privacypolicy'
                              href={REACT_APP_POLICY_URL}
                              rel="noopener noreferrer"
                              target="_blank">
                              {dictionary.privacy_policy}</a>
                          </p>
                          <hr />
                        </Form>
                      </Col>
                      <Col xs={12}>
                        <div className='pt-3 d-flex align-items-center justify-content-center'>
                          <Title2 bold link onClick={() => { this.props.history.push('/signup') }}>
                            {dictionary.signup}
                          </Title2>
                          <Title2 className='pl-2 pr-2'></Title2>
                          <Title2 bold link onClick={() => { this.props.history.push('/reset') }}>
                            {dictionary.reset_password}
                          </Title2>
                        </div>
                      </Col>
                      <Col xs={12} className="text-center">
                        <Title3 bold style={{ cursor: 'pointer' }} onClick={() => { window.location.href = config.landingpage }}>
                          ReadyPlanet &copy; 2018 - {new Date().getFullYear()} All Rights Reserved
                        </Title3>
                        <Title4 secondary>Version {config.package.version}</Title4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
  }
}

const ErrorMessage = ({ isError, message }) => {
  return isError && <div className="text-center pt-1">
    <Title3 danger>{message}</Title3>
  </div>
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
    store_permission: state.permission,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    loginWithEmailAndPassword, shouldRedirect, removeLoginError
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)