import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Sidebar from '../Sidebar'

class Layout extends React.Component {
  render() {
    return <div styled={{ padding: '100px 0px' }}>
      <Sidebar {...this.props}>
        {this.props.children}
      </Sidebar>
    </div>
  }
}


const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)