import firebaseApp from './firebase'

export const loginWithGoogle = () => {
  let provider = new firebaseApp.auth.GoogleAuthProvider().addScope('email')
  return firebaseApp.auth().signInWithRedirect(provider)
}

export const loginWithFacebook = () => {
  let provider = new firebaseApp.auth.FacebookAuthProvider().addScope('email')
  return firebaseApp.auth().signInWithRedirect(provider)
}

export const resetPassword = (email) => {
  return firebaseApp.auth().sendPasswordResetEmail(email)
}