import React from 'react'
import { Spinner } from 'reactstrap'

export default class extends React.Component {
  render() {
    let { type, size, margin } = this.props;
    size = size || '3rem';
    margin = margin || '10px';

    return <div className='d-flex justify-content-center align-items-center' style={{ padding: 10, margin: margin }}>
      <Spinner style={{ width: size, height: size }} type={type} />
    </div>
  }
}