import { LANGUAGE } from '../action_const'
import dictionary from '../../assets/lang'
import Cookies from 'js-cookie'

import moment from 'moment'
import 'moment/locale/th'

let defaultLocale = Cookies.get('__lang') || 'th'

let initState = {
  dictionary: dictionary[defaultLocale]
}

export default (state = initState, action) => {
  switch (action.type) {
    case LANGUAGE.SELECT_LANGUAGE:
      Cookies.set('__lang', action.data, { expires: 356 })
      moment.locale(action.data)
      return Object.assign({}, state, {
        dictionary: dictionary[action.data]
      })
    default:
      return state
  }
}