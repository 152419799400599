import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loadable from 'react-loadable'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom"

import config from './config'
import { theme } from './styles/theme'
import { ThemeProvider } from 'styled-components'

import Login from './pages/Login'
import LoginWithRedirect from './pages/LoginWithRedirect'
import Layout from './components/Layout'
import Loading2 from './components/Loading'
import BusinessLoading from './components/BusinessLoading'

import {
  onAuthStateChanged,
  onAuthRedirectResult
} from './redux/actions/action_permission'

const Loading = () => <div> <Loading2 /> </div>;

const Logout = Loadable({
  loader: () => import('./pages/Logout'),
  loading: Loading,
})
const Register = Loadable({
  loader: () => import('./pages/Register'),
  loading: Loading,
})
const Dashboard = Loadable({
  loader: () => import('./pages/Dashboard'),
  loading: Loading,
})
const ResetPassword = Loadable({
  loader: () => import('./pages/ResetPassword'),
  loading: Loading,
})
const SendEmailVerify = Loadable({
  loader: () => import('./pages/SendEmailVerify'),
  loading: Loading,
})
const EmailVerified = Loadable({
  loader: () => import('./pages/EmailVerified'),
  loading: Loading,
})
const AuthService = Loadable({
  loader: () => import('./pages/AuthService'),
  loading: Loading,
})
const EditProfile = Loadable({
  loader: () => import('./pages/EditProfile'),
  loading: Loading,
})
const ResetPasswordUser = Loadable({
  loader: () => import('./pages/ResetPasswordUser'),
  loading: Loading,
})
const Profile = Loadable({
  loader: () => import('./pages/Profile'),
  loading: Loading,
})
const RedirectPage = Loadable({
  loader: () => import('./pages/RedirectPage'),
  loading: Loading,
})
const Package = Loadable({
  loader: () => import('./pages/Package'),
  loading: Loading,
})
const Invoices = Loadable({
  loader: () => import('./pages/Invoices'),
  loading: Loading,
})
const Payment = Loadable({
  loader: () => import('./pages/Payment'),
  loading: Loading,
})

const { TRACE } = config.diagnostic

class AppRoute extends React.Component {
  componentDidMount() {
    TRACE(`${config.package.description} ${config.package.version}`);
    this.props.onAuthStateChanged()
    this.props.onAuthRedirectResult()
  }

  render() {
    let { isLogin, isVerify, isPageLoading } = this.props.store_permission
    return (
      <ThemeProvider theme={theme}>
        {
          isPageLoading
            ? <Loading />
            : <Router>
              <>
                <Route path="/signin/:service/:token" component={LoginWithRedirect} />
                <Switch>
                  <Route path="/_auth/:service/:code" component={AuthService} />
                  <Route exact path="/verified" component={EmailVerified} />
                  {
                    (isLogin)
                      ? (isVerify)
                        ? <BusinessLoading>
                          <Switch>
                            <Route exact path="/" component={RedirectPage} />
                            <Route exact path="/signout" component={Logout} />
                            <Layout>
                              <Switch>
                                <Route exact path="/dashboard" component={Dashboard} />
                                <Route exact path="/profile" component={Profile} />
                                <Route exact path="/editprofile" component={EditProfile} />
                                <Route exact path="/resetpassword" component={ResetPasswordUser} />
                                <Route exact path="/package" component={Package} />
                                <Route exact path="/invoices" component={Invoices} />
                                <Route exact path="/payment" component={Payment} />
                                <Redirect to="/" />
                              </Switch>
                            </Layout>
                          </Switch>
                        </BusinessLoading>
                        : <Switch>
                          <Route exact path="/verify" component={SendEmailVerify} />
                          <Redirect to="/verify" />
                        </Switch>
                      : <Switch>
                        <Route exact path="/signin" component={Login} />
                        <Route exact path="/signup/:email?" component={Register} />
                        <Route exact path="/reset" component={ResetPassword} />
                        <Redirect to="/signin" />
                      </Switch>
                  }
                </Switch>
              </>
            </Router>
        }
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    onAuthStateChanged, onAuthRedirectResult
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute)