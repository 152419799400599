import { INVOICE } from '../action_const'

let initState = {
  isLoading: false,
  invoices: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case INVOICE.INVOICE_LOADING:
      return Object.assign({}, state, {
        isLoading: action.loading
      })
    case INVOICE.GET_CUSTOMER_INVOICES:
      return Object.assign({}, state, {
        invoices: action.data
      })
    default:
      return state
  }
}